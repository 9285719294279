#content[data-resource='referenzen'] {

  @include widescreen {
    .up {
      position: relative;
      top: -100px;
    }
  }

  ul {
    font-size: 1.6rem;
    line-height: 2.5rem;

    li {
      margin-bottom: 3rem;

      a {
        color: $text-color;
        text-decoration: none;

      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 2.3rem;

    @include tablet{
      font-size: 2.5rem;
    }

    &.underline {
      margin: 60px 0 0;

      @include tablet {
        margin: 70px 0 40px;
      }
    }
  }

  h3 {
    margin-top: 15px;
    font-size: 1.8rem;
    line-height: 3rem;
    text-transform: uppercase;

    @include tablet {
      font-size: 2rem;
      letter-spacing: 1.0rem;
    }

    span.work-in-progress{
      padding: 3px;
      font-size: 1.2rem;
      letter-spacing: 0.4rem;
      color: $background-color;
      background: $text-color;

    }
  }
}
