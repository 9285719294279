#content[data-resource='kundenstimmen'] {

  h3 {
    margin-top: 10px;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.8rem;

    @include tablet {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .block {
    margin-bottom: 5rem;

    @include desktop {
      margin-bottom: 0;
    }
  }

  .col-12.block {
    margin-bottom: 40px;
  }

  .heading {

    h2 {
      margin-top: 40px;
    }
  }

  .more-clients {

    .col-12 {

      margin-bottom: 15px;

      &.dunkel {

        a{
          background: $text-color;
        }

      }

      a {
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
        align-items: center;
        background: transparent;
      }

    }

    img {
      max-width: 250px;
      max-height: 120px;
      width: auto;
    }
  }
}