footer{

  padding: 90px 0 0;
  text-align: center;

  @include tablet {
    padding: 120px 0 10px;
  }

  @include desktop{
    padding-bottom: 40px;
  }

  font-size: 1.6rem;

  .footer-content{

    line-height: 3rem;

    .row{
      margin-bottom: 15px;

      @include tablet{
        margin-bottom: 0;
      }

      @include desktop{
        //width: 100%;
      }

      .col-12{

        @include tablet{
          text-align: left;
        }

        &.rechtliches{
          margin-top: 20px;

          @include tablet{
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          }

          @include desktop{
            display: block;
            margin-top: 0;
            text-align: right;
          }
        }

        a{
          color: $text-color;

          &:hover{
            color: $link-color;
          }
        }
      }
    }
  }
}
