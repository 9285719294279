#content[data-resource='leistungen']{

  article{
    margin-bottom: 30px;

    h2{
      margin-bottom: 10px;
    }
  }

}