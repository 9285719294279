#contactform{

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;

  input{
    width: 100%;
    padding: 0.7rem 0;
    font-weight: 400;
    color: $text-color;

    &::placeholder{
      color: $input-field-border-color;
      opacity: 1;
    }
  }

  textarea{
    width: 100%;
    color: $text-color;

    &::placeholder{
      color: $input-field-border-color;
      opacity: 1;
    }
  }

  .form-field{
    //width: 45%;
    margin-bottom: 3.5rem;

    &.full{
      width: 100%;
      margin-top: 3.5rem;
      margin-bottom: 5rem;
    }

    .error{
      font-size: 1.7rem;
      color: $red;
    }
  }

  button.underline{
    width: 180px;
  }

}