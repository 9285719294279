button {

  &[type=submit] {

    background: none;
    border: none;

  }

  &.underline {
    display: inline-block;
    position: relative;
    padding: 0 0 1rem;
    color: $text-color;
    text-transform: uppercase;
    letter-spacing: 1.5rem;
    margin-right: -1.5rem;
    font-weight: 500;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: 2px;
      right: 0.5rem;
      height: 3px;
      background: $text-color;
    }
  }
}