nav#menu {

  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: auto;
  min-height: 100%;
  top: 0;
  right: -15px;
  padding-top: 15px;
  background: $text-color;
  color: $background-color;

  transition: right 0.2s ease;

  &.gone {
    right: calc( -100vw - 15px );

    @include widescreen{
      right: -545px;
    }
  }

  @include phone{
    max-width: 50vw;
  }

  @include desktop {

    width: 45vw;
  }

  @include widescreen {

    width: 25vw;
    max-width: 530px;
  }

  img.menu {
    width: 75px;
    height: auto;

    @include desktop {
      display: none;
    }
  }

  ul {
    margin-top: 58px;
    padding: 0;
    list-style: none;
    text-align: center;

    @include desktop{
      margin-top: 145px;
    }

    li {
      padding: 1rem 0;

      @include tablet{
        padding: 20px 0;
      }
    }

  }

}