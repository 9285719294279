#content[data-resource='ueber'] {

  p {

    &.cropped {
      @include text-crop(1.7);
    }

  }

  figcaption {
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6;
  }

  .image {
    margin-bottom: 20px;

    @include desktop{
      margin-bottom: 0;
    }
  }

  .text {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    p {
      order: 2;

      @include widescreen {
        order: 1;
        margin-bottom: 20px;
      }
    }

    h1 {

      order: 1;
      margin-bottom: 0;

      @include tablet{
        margin-bottom: 25px;
      }

      @include widescreen {
        order: 2;
        margin-bottom: 0;
      }
    }
  }

  .why {

    margin-top: 20px;

    @include tablet{
      margin-top: 70px;

      h3{
        font-size: 1.8rem;
        
        @include widescreen{
          font-size: 2.5rem;
        }
      }

      p {
        margin-top: 10px;
      }
    }

    @include desktop {
      width: 50vw;
      margin: 0 auto;
      flex-basis: auto;
      margin-top: 180px;
      margin-bottom: 120px;

    }
  }
}