#content[data-resource='datenschutz'] {

  h1,h2,h3{
    font-size: 90%;
  }

  p{
    font-size: 85%;
  }

  a{
    max-width: 100px;
    word-break: break-all;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  @include phone{
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.8rem;
    }

    h3 {
      font-size: $font-size-base;
    }

    p{
      font-size: $font-size-base;
    }
  }
}