.content.reference {

  h2{
    margin-bottom: 0;

    @include tablet{
      margin-bottom: 30px;
    }
  }

  h3{
    letter-spacing: .4rem;

    @include tablet{
      letter-spacing: .7rem;
    }
  }

  .impressions{
    margin-top: 40px;
    margin-bottom: 40px;

    h3{
      margin-bottom: 15px;
    }
  }

  .meta, h3{
    font-size: 2rem;
    line-height: 3.5rem;

    .col-12{
      margin-bottom: 20px;
    }
  }

  .statement{
    margin-top: 30px;

    @include desktop{
      margin-top: 80px;
    }
  }

  .techs{

    span:last-child{
      display: none;
    }
  }

  img{
    max-width: 100%;
  }
}