html {
  font-size: 62.5%;
}

body {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  margin-bottom: 0;

  color: $text-color;
  font-family: $font-family-sans-serif;
  background: $background-color;

  &.container-fluid {
    font-size: 2rem;
    font-weight: 200;
    letter-spacing: .2rem;
    line-height: 1.7;

    @include tablet{
      font-size: $font-size-base;
    }
  }

  #app {
    position: relative;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;

    overflow: hidden;
    overflow-y: auto;


    @include desktop{
      padding: 15px;
    }
  }

  a {
    color: $link-color;
    text-decoration: none;
  }

  p {

    margin: 0;

    a {
      padding: 0.5rem 0;
      color: $link-color;
      text-decoration: none;

      &:hover {
        color: $text-color;
      }
    }
  }

  figure{
    margin: 0;
    line-height: 1;
  }

  img{
    max-width: 100%;
  }

}

ul {
  list-style: none;
  padding: 0;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  margin-bottom: 0;
  font-size: $font-size-base;
  font-weight: 600;
}

h1, h2, h3 {

  margin-top: 0;

  &.underline {

    display: inline-block;
    position: relative;
    padding-bottom: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.5rem;

    @include tablet {

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 2px;
        right: 0.5rem;
        height: 3px;
        background: $text-color;
      }
    }
  }

  &.uppercase {

    letter-spacing: .7rem;
    text-transform: uppercase;
  }
}

h2.main {
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 30px;
  line-height: 2rem;
  font-weight: 600;
}