.js-cookie-consent.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  text-align: left;

  padding: 15px;
  font-size: 1.8rem;
  line-height: 3rem;
  background: $background-color;
  border-top: 2px solid $text-color;

  @include phone {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span{
    display: block;

    @include desktop{
      //margin-bottom: 15px;
    }

  }

  button {
    padding: 1rem 2rem;
    background: $text-color;
    border: none;
    color: $background-color;
    cursor: pointer;

    @include phone {
      margin-left: 2rem;
    }
  }
}