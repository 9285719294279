// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

// Bootstrap
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/utilities/align";
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";
//@import '~bootstrap/scss/root';
//@import '~bootstrap/scss/grid';

// Mixins
@import "mixins/mediaqueries";
@import "mixins/text-crop";

// Variables
@import 'variables';
@import "global";

// Components
@import "components/header";
@import "components/footer";
@import "components/menu";
@import "components/form";
@import "components/buttons";
@import "components/cookie_consent";

// Views
@import 'views/home';
@import 'views/leistungen';
@import "views/applikation";
@import "views/technologien";
@import "views/referenzen";
@import "views/referenz";
@import "views/kundenstimmen";
@import "views/kontakt";
@import "views/ueber";
@import "views/datenschutz";


