header{

  padding-bottom: 80px;

  @include desktop{
    padding: 3rem 3rem 5rem;
  }

  nav{

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    a{
      line-height: 0;
    }

    @include desktop {

    }

    img{
      width: 75px;

      &.menu{

        width: 50px;

        @include tablet{
          width: 75px;
        }

        @include widescreen{
          width: 80px;
        }

        opacity: .6;
      }
    }
  }
}