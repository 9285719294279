textarea, input, button, select { font-family: inherit; font-size: inherit; }

input{
  color: $text-color;
  border: solid $input-field-border-color;
  border-width: 0 0 2px 0;
  background: none;

  &:focus{
    border-color: $text-color;
  }
}

textarea{
  color: $text-color;
  border: solid $input-field-border-color;
  border-width: 0 0 2px 0;
  background: none;

  &:focus{
    border-color: $text-color;
  }
}

input,textarea,select,button[type=submit]{
  color: $text-color;
  font-size: $font-size-base;
}