#content[data-resource='technologien']{

  h3{
    margin: 3rem 0 2rem;
  }

  .inner{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;

    img{
      display: block;
      margin: 1rem;
    }
  }
}