#content[data-resource='home'] {

  h1 {

    text-transform: uppercase;
    font-weight: 600;

    @include phone {
      font-size: 4.7rem;
      letter-spacing: 1rem;
    }

    @include tablet {
      font-size: 6rem;
      letter-spacing: 1.6rem;
    }

    @include desktop{
      margin-bottom: 40px;
      font-size: 3.8rem;
      letter-spacing: 3.3rem;
    }

    @include widescreen {
      margin-bottom: 50px;
      font-size: 5.4rem;
    }
  }

  h3 {
    padding-bottom: 0.5rem;
    margin-bottom: 20px;

    &.underline {
      width: 100%;
      margin-top: 20px;
      text-align: center;

      @include tablet {
        width: auto;
        margin-top: 60px;
      }
    }
  }

  .welcome{

    margin-top: 30px;

    @include widescreen{
      margin-top: 50px;
    }
  }

  .statement {

    margin-top: 40px;

    @include tablet{
      margin-top: 60px;
      text-align: center;
    }

    @include widescreen{
      margin-top: 120px;
    }

    blockquote {

      margin: 0;
      font-weight: 400;

      @include widescreen{
        width: 35vw;
        margin: 0 auto;
        font-size: 3.9rem;
        line-height: 6rem;
      }

      cite {
        display: block;
        font-size: 2.2rem;
        font-weight: 200;
      }
    }

  }

  .callout {
    display: none;
  }

}